import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Spinner } from '@amzn/awsui-components-react-v3';
import OIDC from '@awsscm/awsscm-auth-manager/auth';
import { Auth } from '@awsscm/awsscm-auth-manager/auth/AuthManager';
import { AuthSession, AuthUser } from '@awsscm/awsscm-auth-manager/auth/type';
import { Level } from '@katal/logger';
import KatalMetricsPublisher from '@katal/metrics/lib/KatalMetricsPublisher';
import { ArgoAppContextProvider } from '../../../ArgoContext';
import { credentialStorage } from '../../../utils/CredentialStorage';
import katalLogger from '../../../utils/logger/logger';
import initialMetricsPublisher, { ArgoMetricsConfig } from '../../../utils/metrics/metrics';
import argoHadesClient from '../../../utils/ArgoHades/index';
import { LegalAgreementCaptivePortal } from '../../legalAgreement';
import { fetchWithWebIdentity, fetchWithAWSAuth } from '../../../utils/helperMethods/FederatedIdentity';
import { sendInternalExternalMetric } from './internalExternalMetric';
import { CognitoAppProps } from './CognitoApp.types';
import { AuthConfig } from '../../../ArgoApp.types';
import { GetAcceptanceForUserOut, GetDocumentContentOut } from '../../../utils/ArgoHades/interfaces';
import { queueApplicationStartMetric } from './queueApplicationStartMetric';
import { useUserUsageMetrics } from '../../../utils/metrics/userUsageMetrics/useUserUsageMetrics';
import { BROWSER_SESSION_ID_KEY } from '../../../utils/metrics/userUsageMetrics/constants';

const SESSION_REFRESH_PERIOD = 600000;

let authSession: AuthSession | null = null;

function sendArgoHadesMetric(metricsPublisher: KatalMetricsPublisher, apiName: string, latency: number) {
  metricsPublisher.newChildActionPublisherForMethod('ArgoHadesLatency').publishTimerMonitor(apiName, latency);
}

export const CognitoApp = (props: CognitoAppProps) => {
  const [authInitialized, setAuthInitialized] = React.useState<boolean>(false);
  const [legalAgreementAccepted, setLegalAgreementAccepted] = React.useState<boolean>(props.region !== 'us-west-2');
  const [siteTermsDocument, setSiteTermsDocument] = React.useState<string>('');

  const logger = katalLogger(
    Level.INFO,
    {
      appName: props.appName
    },
    false,
    props.katalLoggerUrl
  );

  const cognitoAppMetricsConfig: ArgoMetricsConfig = {
    appName: props.appName,
    region: props.cognito.region,
    frameworkStage: props.frameworkStage,
    cell: props.cell,
    browserName: props.browserName,
    deviceType: props.deviceType,
    deviceOS: props.deviceOS,
    locale: props.locale,
    applicationVisitId: props.applicationVisitId,
    argoSessionId: props.argoSessionId,
    loggerUrl: props.katalLoggerUrl
  };

  const metricPublisher = initialMetricsPublisher(cognitoAppMetricsConfig);

  // On initial render, fire application Warm/Cold start metric
  React.useEffect(() => {
    queueApplicationStartMetric(metricPublisher);
  }, []);

  // When the props.cognito object assignment is modified (functionally, this only happens on initial render),
  // setup OIDC and trigger the sign in flow.
  React.useEffect(() => {
    let cancelled = false;
    const cognito = props.cognito as AuthConfig;

    OIDC.configure({
      region: cognito.region,
      clientId: cognito.am_clientId,
      authorityUrl: cognito.am_identityProviderUrl,
      identityProviderUrl: cognito.am_identityProviderUrl,
      domain: cognito.domain,
      scope: cognito.am_scope,
      redirectSignIn: cognito.am_redirectSignIn,
      redirectSignOut: cognito.am_redirectSignOut,
      responseType: cognito.am_responseType,
      jwkEndpoint: cognito.am_jwkEndpoint,
      authorizationEndpoint: cognito.am_authorizationEndpoint,
      endSessionEndpoint: cognito.am_endSessionEndpoint,
      sessionServiceEndpoint: cognito.am_sessionServiceEndpoint,
      tokenExpirationTime: 900,
      implicitFlowRefreshEndpoint: cognito.am_implicitFlowRefreshEndpoint,
      tokenEndpoint: cognito.am_tokenEndpoint
    });

    function onResolvedSession(session: AuthSession | null) {
      if (!cancelled) {
        authSession = session;
        if (!authInitialized) {
          setAuthInitialized(true);
        }
      }
    }

    Auth.signinSession()
      .then(onResolvedSession)
      .catch(() => {
        // If there's an error with sign-in not due to application unmount, retry sign-in after 500ms.
        // TODO: evaluate if this is actually worthwhile. We don't yet have metrics to know how common this usecase is,
        // but the static wait here seems like a code smell.
        if (!cancelled) {
          setTimeout(() => {
            Auth.signinSession()
              .then(onResolvedSession)
              .catch((error) => {
                logger.error('Unable to sign in', error);
              });
            // 500ms matches what the Argo portal currently does and "works" in testing.
            // https://tiny.amazon.com/160ql49xw/codeamazpackAWSSblob5362src
          }, 500);
        }
      });

    return () => {
      cancelled = true;
    };
  }, [props.cognito]);

  function onAcceptLegalAgreement(userName: string) {
    setLegalAgreementAccepted(true);
    argoHadesClient.setAcceptanceForUser(userName);
  }

  // After a user has been signed in, check to see if they have agreed to the Terms of Use.
  React.useEffect(() => {
    if (!legalAgreementAccepted) {
      if (credentialStorage.getJwtToken() !== '') {
        const getAcceptanceStartTime = performance.now();
        argoHadesClient.getAcceptanceForUser(credentialStorage.getUserName())
          .then((getAcceptanceResult: GetAcceptanceForUserOut) => {
            const getAcceptanceDuration = performance.now() - getAcceptanceStartTime;
            sendArgoHadesMetric(metricPublisher, "GetAcceptanceForUserLatency", getAcceptanceDuration);

            if (getAcceptanceResult.hasAccepted) {
              setLegalAgreementAccepted(true);
            } else {
              const getSiteTermsStartTime = performance.now();
              argoHadesClient.getSiteTerms().then((getDocumentResult: GetDocumentContentOut) => {
                const getSiteTermsDuration = performance.now() - getSiteTermsStartTime;
                sendArgoHadesMetric(metricPublisher, "GetSiteTermsLatency", getSiteTermsDuration);

                setSiteTermsDocument(getDocumentResult.content);
              });
            }
          })
          .catch((error) => {
            logger.error('Error in getAcceptanceForUser request', error);
            argoHadesClient.getSiteTerms().then((getDocumentResult: GetDocumentContentOut) => {
              setSiteTermsDocument(getDocumentResult.content);
            });
          });
      }
    }
  }, [authInitialized]);

  // After a user has signed in, initialize user usage tracking metrics
  useUserUsageMetrics(metricPublisher, authInitialized, props.appName);

  /*
    Because this is inline in the rendering function, we have this if statement
    to ensure that the setInterval is only set up once.

    If this does not exist then it would be created twice, once on the initial render and once on
    the rerender when authInitialized is set.
   */
  if (!authInitialized) {
    setInterval(() => {
      let cancelled = false;
      Auth.refreshSession()
        .then((session) => {
          if (!cancelled) {
            authSession = session;
            const refreshedToken = session!.idToken;
            const refreshedJwtToken = refreshedToken;
            if (session!.credentials !== null && session!.credentials !== undefined) {
              credentialStorage.setUserAttributes(refreshedJwtToken, session!.credentials.userDirectoryGroupList);
              credentialStorage.setUserCredentials(session!.credentials.credentials);
            } else {
              credentialStorage.setUserAttributes(refreshedJwtToken);
            }
          }
        })
        .catch((error) => {
          logger.error('Unable to refresh session', error);
        });
      return () => { cancelled = true; };
    }, SESSION_REFRESH_PERIOD);
  }

  if (authSession && authInitialized) {
    const getToken = () => { return authSession!.idToken; };
    const jwtToken = getToken();
    if (authSession!.credentials !== null && authSession!.credentials !== undefined) {
      credentialStorage.setUserAttributes(jwtToken, authSession!.credentials.userDirectoryGroupList);
      credentialStorage.setUserCredentials(authSession!.credentials.credentials);
    } else {
      credentialStorage.setUserAttributes(jwtToken);
    }
    props.onApplicationVisitIdChange(uuidv4());
    props.onArgoSessionIdChange(credentialStorage.getAtHash());

    const context = {
      user: {
        username: credentialStorage.getUserName(),
        signOut: () => {
          authSession = null;
          setAuthInitialized(false);
          sessionStorage.removeItem(BROWSER_SESSION_ID_KEY);
          Auth.signOut();
        },
      },
      frameworkStage: props.frameworkStage,
      frontEndFeatureSet: props.frontEndFeatureSet,
      fetchWithWebIdentity: (input: RequestInfo, init?: RequestInit) => {
        const token = credentialStorage.getJwtToken();
        return fetchWithWebIdentity(input, metricPublisher, token, init);
      },
      fetchWithAwsAuth: (input: RequestInfo, init?: RequestInit) => {
        return fetchWithAWSAuth(input, metricPublisher, authSession, jwtToken, props.region, init);
      },
      custom_groups: credentialStorage.getCustomGroups(),
      argoHadesUrl: props.argoHadesUrl,
      initialMetricsPublisher: metricPublisher,
      logger,
      region: props.region
    };

    context.logger.info('Initial Application StartUp Log', {
      browserName: props.browserName,
      deviceType: props.deviceType,
      deviceOS: props.deviceOS,
      locale: props.locale,
      appName: props.appName,
      cell: props.cell,
      region: props.cognito.region,
      stage: props.frameworkStage,
      applicationVisitId: props.applicationVisitId,
      argoSessionId: props.argoSessionId
    });

    // This metric should not be sent until after credentialStorage.setUserAttributes has been called with the JWT
    sendInternalExternalMetric(metricPublisher, credentialStorage);

    if (legalAgreementAccepted) {
      return (
        <ArgoAppContextProvider value={context}>
          {props.content}
        </ArgoAppContextProvider>
      );
    } else if (siteTermsDocument !== '') {
      return (
        <LegalAgreementCaptivePortal
          onSubmitContinue={() => {
            onAcceptLegalAgreement(credentialStorage.getUserName());
          }}
          siteTermsText={siteTermsDocument}
        />
      );
    } else {
      return (
        <div className='loading'>
          <Box color='text-status-inactive'>
            <Spinner /> Loading
          </Box>
        </div>
      );
    }
  }
  return (
    <div className='loading'>
      <Box color="text-status-inactive">
        <Spinner /> Loading
      </Box>
    </div>
  );
};